<template>
  <div class="card full-height bg-ligth">
    <div class="card-header">
      <h3 class="mb-0">Funcionários</h3>
    </div>
    <div class="card-body">
      <div class="row d-flex align-items-end">
        <div class="form-group col-md-3">
          <label>Tipo</label>
          <select v-model="pesquisa.tipo" class="form-control">
            <option value="rg">rg</option>
            <option value="nome_sobrenome">Nome</option>
          </select>
        </div>
        <div class="form-group col-md-3">
          <label for="text">Campo</label>
          <input
              v-model="pesquisa.texto"
              type="text"
              class="form-control"
              id="text"
              placeholder
          />
        </div>
        <div class="form-group">
          <button class="btn btn-success" @click="pesquisar()">
            <i class="fa fa-search search-icon m-0 p-0"></i>
          </button>
        </div>
      </div>
      <b-table
          class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          show-empty
          empty-text="Nenhum registro encontrado!"
          :fields="['id', 'nome', 'cpf', 'rg', 'status', 'email', 'actions']"
          :items="lista_usuarios"
          :per-page="perPage"
          :current-page="currentPage"
          id="funcionarios-table"
      >
        <template #cell(status)="data">
          <span class="label label-lg label-inline px-2 pb-2 pt-3" :class="{
            'label-light-success': data.item.status === 1,
            'label-light-danger': data.item.status === 0,
            'label-light-warning': data.item.status === 2,
          }">
            {{ data.item.status === 1 ? 'Ativo' : 'Inativo' }}
          </span>
        </template>
        <template #cell(actions)="{item}">
          <div class="dropdown show">
            <a class="btn btn-sm btn-hover-light-primary" data-toggle="dropdown">
              <i class="ki ki-bold-more-ver p-0"></i>
            </a>

            <div class="dropdown-menu ">
              <a class="dropdown-item" @click="trocar_pagina('perguntas',item.id)">Perguntas</a>
              <a class="dropdown-item" @click="atualizar(item)">Editar</a>
              <a class="dropdown-item" @click="financeiro('pedidoCompraUsuario',item.id)">Despesas</a>
              <!-- <a class="dropdown-item" href="#">Pagamentos</a> -->
              <a
                  class="dropdown-item"
                  href="#"
                  @click.prevent="showModalPermission(item)"
                  v-show="lista_permissoes_filial.r_Permission"
              >
                Permissões
              </a>
            </div>
          </div>
        </template>
      </b-table>
      <b-pagination
          v-model="currentPage"
          :total-rows="lista_usuarios.length"
          :per-page="perPage"
          aria-controls="funcionarios-table"
      >
      </b-pagination>
    </div>
    <b-modal size="lg" id="modal-roles" title="Vincular Funções" centered ok-only :ok-disabled="disableSaveModal"
             ok-title="Salvar" @ok="submitPermissions">
      <div class="form-group row">
        <div class="col-md-12">
          <label class="col-md-12">Funções:*</label>
          <treeselect
              multiple
              :options="lista_roles"
              placeholder="Selecione as funções..."
              v-model="roleForm.roles"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import {fireAlert} from "@/core/mixins/alertMessageMixin";


export default {
  mixins: [fireAlert],
  data() {
    return {
      selectedUser: null,
      disableSaveModal: false,
      roleForm: {
        user_id: null,
        roles: []
      },
      pesquisa: {
        tipo: 'nome',
        texto: "",
      },
      currentPage: 1,
      perPage: 5,
      value: "",
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Funcionarios"}]);
  },
  created() {
    this.listar_usuarios_matriculas();
  },
  computed: {
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_roles() {
      return this.$store.state.permissoes.lista_roles.map(role => ({
        id: role.id,
        label: role.name
      }));
    },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios_matriculas.filter(item => {
        if (!this.pesquisa.texto) return true
        return String(item[this.pesquisa.tipo]).toLowerCase().indexOf(this.pesquisa.texto.toLowerCase()) !== -1
      });
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
  },
  methods: {
    async submitPermissions(e) {
      e.preventDefault()
      this.disableSaveModal = true
      this.$store.dispatch("permissoes/vincular_regra_usuario", this.roleForm)
          .then(() => {
            this.roleForm.roles = []
            this.$bvModal.hide('modal-roles')
            this.fireAlert({title: 'Atualizado com sucesso'})
          })
          .catch(() => this.fireAlert(this.mensagem_alert))
          .finally(() => this.disableSaveModal = false)
    },
    async showModalPermission(item) {
      console.log(item)
      this.roleForm.user_id = item.user_id
      this.$bvModal.show('modal-roles')
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("permissoes/read_user_roles", item.user_id);
      this.roleForm.roles = this.$store.state.permissoes.user_roles.reduce((prev, curr) => [...prev, curr.id], [])
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async listar_usuarios_matriculas() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configUsuarios/listar_usuarios_matriculas");
      await this.$store.dispatch("permissoes/all_roles");
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      this.$store.dispatch("configEmpresa/atualizar", "");
    },
    atualizar(value) {
      this.$router.push({name: "configUsuarios/createFuncionario"});
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async pesquisar() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("configUsuarios/pesquisar_usuarios", this.pesquisa);
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    async trocar_pagina(name,id) { 
          await  this.$store.dispatch("configEmpresa/guardarDados", id);
      this.$router.push({name: name});
    },

    financeiro(name,id){
        this.$router.push({name: name, params: {usuario_id: id}});
    },
}
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>